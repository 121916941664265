<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn">
      <template #tail>
        <a-button  type="primary" @click="exportAllExcel">导出</a-button>
      </template>
    </SearchList>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle flex">共有&nbsp;<a>{{ data.length }}</a>&nbsp;条数据
        <div class="m-l2 flex">合计：
          <div v-for="item in navList" :key="item.key" class="m-r1">
            <div>{{ item.title }}&nbsp;&nbsp;<span style="color:#0066CC">￥{{ item.value }}</span></div>
          </div>
        </div>
      </div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #paidNormalAmt="{ record }">
          <a @click="openDetails(record,1,'paidNormalAmt')">{{ record.paidNormalAmt }}</a>
        </template>
        <template #paidOverdueAmt="{ record }">
          <a @click="openDetails(record,2,'paidOverdueAmt')">{{ record.paidOverdueAmt }}</a>
        </template>
        <template #monthNormalAmt="{ record }">
          <a @click="openDetails(record,3,'monthNormalAmt')">{{ record.monthNormalAmt }}</a>
        </template>
        <template #monthOverdueAmt="{ record }">
          <a @click="openDetails(record,4,'monthOverdueAmt')">{{ record.monthOverdueAmt }}</a>
        </template>
        <template #totalAmt="{ record }">
          <a @click="openDetails(record,0,'totalAmt')">{{ record.totalAmt }}</a>
        </template>
        <template #receiveContent="{ record }">
          <div class="flex al-center">
            <div style="width: 85%;">
              <span class="m-r1" :style="{color:record.receiveLevel?.value === 1 ? '#15AD31':'#DB2107'}">{{ record.receiveLevel?.label }}</span>
              {{ record.receiveContent }}
            </div>
            <div>
              <a v-if="record.receiveId" @click="recordLook(record)">记录</a>
            </div>
          </div>
        </template>
        <template #financeDescContent="{ record }">
          <div class="flex al-center">
            <div style="width: 84%;">
              {{ record.financeDescContent }}
            </div>
            <div>
              <div> <a @click="newAdd(record)">添加</a></div>
              <a v-if="record.financeDescId" @click="financLook(record)">记录</a>
            </div>
          </div>
        </template>
      </a-table>
    </div>
    <a-modal v-model:visible="remarkVisible" title="回款描述" @cancel="remarkValue = null" @ok="submit">
      <div class="m-b1">备注<span style="color:red">*</span></div>
      <a-textarea :rows="4" v-model:value="remarkValue" placeholder="请输入"></a-textarea>
    </a-modal>
    <a-modal title="回款描述记录" v-model:visible="financLookVisible" footer="" @cancel="financeDescListData = []">
      <div class="recordLookBox">
        <div v-for="item in financeDescListData" :key="item.id" class="m-b1">
          <a-card>
            <div>备注内容：<span>{{item.content}}</span></div>
            <div>添加人：<span>{{item.empName}}</span></div>
            <div>添加时间：<span>{{item.createTime}}</span></div>
          </a-card>
        </div>
      </div>
    </a-modal>
   <!-- 月份列表 -->
   <a-modal v-model:visible="monthVisible" :title="monTitle" footer="" width="80%" destroyOnClose :bodyStyle="{padding:'10px',background:'#eee'}">
      <DetailsModal :monthData="monthData"></DetailsModal>
    </a-modal>
    <a-modal title="回款描述记录" v-model:visible="recordLookVisible" footer="" @cancel="receiveData = []">
      <div class="recordLookBox">
        <div v-for="item in receiveData" :key="item.id" class="m-b1">
          <a-card>
            <div>回款情况：<span>{{item.receiveLevel?.label}}</span></div>
            <div>添加人：<span>{{item.empName}}</span></div>
            <div>添加时间：<span>{{item.createTime}}</span></div>
            <div>添加内容：<span>{{ item.content }}</span></div>
          </a-card>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import moment from 'moment'
import { transitionTimeYM, getTableBody, IfNull, compute } from '@/utils/util'
import { receivableTotal, financeDescAdd, financeDescList } from '@/api/financialCenter/financialCenter'
import DetailsModal from '../../table/keyIssues/detailsModal'
import {  receivePlanId } from '@/api/businessCenter/businessCenter'
import { useStore } from 'vuex'
import * as XLSX from 'xlsx';
import { message } from 'ant-design-vue'
const store = useStore()

const loading = ref(false)
const dataSource = ref([])
const data = ref([])
const pageSize = ref(50)
const tableRef = ref(null)
const monthVisible = ref(false)
const searchMsg = ref({})
const monTitle = ref({})
const monthData = ref({})
const recordLookVisible = ref(false)
const receiveData = ref([])
const remarkVisible = ref(false)
const remarkValue = ref('')
const recordRemark = ref({})
const  financeDescListData = ref([])
const financLookVisible = ref(false)
const navList = ref([
  { title: '正常', value: 0, key1: 'paidNormalAmt',key2:'monthNormalAmt' },
  { title: '逾期', value: 0, key1: 'paidOverdueAmt',key2:'monthOverdueAmt' },
  { title: '合计', value: 0, key1: 'totalAmt', },
])
const financLook = (record) => {
  financLookVisible.value = true
  financeDescList(record.financeDescId).then( res => {
    if(res.code !== 10000) return
    financeDescListData.value = res.data
  })
}

// 财务描述新增
const newAdd = (record) => {
  recordRemark.value = record
  remarkVisible.value = true
}
const submit = () => {
  if(!remarkValue.value){
     message.error('请输入内容')
     return
  }
  financeDescAdd({
    content: remarkValue.value,
    customerId: recordRemark.value.customerId,
    empId: recordRemark.value.salesmanId ,
    creator: recordRemark.value.salesman
  }).then( res => {
    if (res.code !== 10000) return
     message.success('添加成功')
     remarkVisible.value = false
     remarkValue.value = ''
     getProfitList()
  })
}
const recordLook = (record) => {
  recordLookVisible.value = true
  receivePlanId(record.receiveId).then(res => {
    if (res.code !== 10000) return
    receiveData.value = res.data
  })
}
const searchAdd = (e) => {
  searchMsg.value = e
  getProfitList()
}
const resetBtn = () => {
  searchMsg.value = {}
  getProfitList()
}
const arr = {
  0: '合计',
  1: '到收-正常',
  2: '到收-逾期',
  3: '记账/月结-正常',
  4: '记账/月结-逾期'
}

const openDetails = (record, type, key) => {
  if(record[key] == 0) return
  monTitle.value = `业务应收-${arr[type]}-` + record.salesman
  monthVisible.value = true
  record.type = type
  record.key = key
  record.title = monTitle.value
  monthData.value = record
}
const getProfitList = () => {
  pageSize.value = 50
  loading.value = true
  receivableTotal({
    ...searchMsg.value,
  }).then(res => {
    if (res.code !== 10000) return
    data.value = res.data
    navList.value.forEach(opt => {
      if(data.value.length > 0){
        if(opt.key2){
          opt.value = compute('+', ...data.value.map(item => item[opt.key1]),...data.value.map(item => item[opt.key2])).result
        }else{
          opt.value = compute('+', ...data.value.map(item => item[opt.key1])).result
        }
      }else{
        opt.value = 0
      }
    })
    console.log(navList.value);
    dataSource.value = data.value.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}
const exportAllExcel = () => {
  // 获取用户列表
  let tableData = [
    ['序号', '部门', '分单员', '客户名称', '到收-正常', '到收-逾期', '记账/月结-正常', '记账/月结-逾期', '合计', '上报报备时间', '报备次数', '回款描述','财务描述']//导出表头
  ] // 表格表头
  data.value.forEach((item, index) => {
    let rowData = []
    let str = item.receiveLevel ? item.receiveLevel.label : ' ' 
    let str1 = item.receiveContent ? item.receiveContent : ' '
    let str3 =str+' ' + str1
    //导出内容的字段
    rowData = [
      index+1,
      item.orgName,
      item.salesman,
      item.customerName,
      item.paidNormalAmt,
      item.paidOverdueAmt,
      item.monthNormalAmt,
      item.monthOverdueAmt,
      item.totalAmt,
      item.receiveReportTime,
      item.receivePlanNum,
      str3 ,
      item.financeDescContent
    ]
    tableData.push(rowData)
  })

  let workSheet = XLSX.utils.aoa_to_sheet(tableData);
  let bookNew = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(bookNew, workSheet, '') // 工作簿名称
  let name = '业务应收' + '.xlsx'
  XLSX.writeFile(bookNew, name) // 保存的文件名
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
onMounted(() => {
  getProfitList()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
})
const iptData = ref([
  {
    type: "select",
    placeholder: "部门",
    value: null,
    prop: "orgId",
    width: 200,
    showSearch:true,
    opt:store.state.app.orgList
  },
  {
    type: "input",
    placeholder: "分单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "select",
    placeholder: "结算方式",
    value: null,
    prop: "orderSettlement",
    width: 200,
    key: "value",
    keyVal: "label",
    opt:store.state.enumAll.orderSettlementList
  },
  {
    type: "select",
    placeholder: "是否添加回款记录",
    value: null,
    prop: "isAdd",
    width: 200,
    opt: [
      { name: "是", id: 1 },
      { name: "否", id: 0 },
    ],
  },
  {
    type: "select",
    placeholder: "回款情况",
    value: null,
    prop: "receiveLevel",
    width: 200,
    opt: [
      { name: "正常", id: 1 },
      { name: "困难", id: 2 },
    ],
  },
  {
    type: "input",
    placeholder: "财务描述",
    value: null,
    prop: "financeDescContent",
    width: 200,
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 70
  },
  {
    title: '部门',
    dataIndex: 'orgName',
    align: 'left',
    opt:store.state.app.orgList
  },
  {
    title: '分单员',
    dataIndex: 'salesman',
    align: 'left',
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'left',
  },
  {
    title: '到收',
    align: 'center',
    children: [
      {
        title: '正常',
        dataIndex: 'paidNormalAmt',
        slots: {
          customRender: 'paidNormalAmt'
        },
        align: 'right',
      },
      {
        title: '逾期',
        dataIndex: 'paidOverdueAmt',
        slots: {
          customRender: 'paidOverdueAmt'
        },
        align: 'right',
      }
    ]
  },
  {
    title: '记账/月结',
    align: 'center',
    children: [
      {
        title: '正常',
        dataIndex: 'monthNormalAmt',
        slots: {
          customRender: 'monthNormalAmt'
        },
        align: 'right',
      },
      {
        title: '逾期',
        dataIndex: 'monthOverdueAmt',
        slots: {
          customRender: 'monthOverdueAmt'
        },
        align: 'right',
      }
    ]
  },
  {
    title: '合计',
    dataIndex: 'totalAmt',
    slots: {
      customRender: 'totalAmt'
    },
    align: 'right',
  },
  {
    title: '上报报备时间',
    dataIndex: 'receiveReportTime',
    align: 'left',
  },
  {
    title: '报备次数',
    dataIndex: 'receivePlanNum',
    align: 'right',
    width: 80
  },
  {
    title: '回款描述',
    dataIndex: 'receiveContent',
    align: 'left',
    slots: {
      customRender: 'receiveContent'
    },
    width: 250
  },
  {
    title: '财务描述',
    dataIndex: 'financeDescContent',
    align: 'left',
    slots: {
      customRender: 'financeDescContent'
    },
    width: 250
  },
])
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>