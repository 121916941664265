<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle flex">共有 <a>{{total}}</a> 条数据
        <div class="m-l2 flex">合计：
          <div v-for="item in navList" :key="item.key" class="m-r1">
            <div>{{ item.title }}&nbsp;&nbsp;<span style="color:#0066CC">￥{{ item.value }}</span></div>
          </div>
        </div>
      </div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #vinNo="{ record }">
          <div>{{ record.vinNo }}</div>
          {{ record.brand }}&nbsp;{{ record.model }}
        </template>
        <template #add="{ record }">
          {{ record.lossReason }}
        </template>
      </a-table>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import { financeCenterOrgDetail } from '@/api/financialCenter/financialCenter'
import { getTableBody, IfNull, compute } from '@/utils/util'
import { message } from 'ant-design-vue'
const props = defineProps({
  empId: {
    type: String,
    default: ''
  },
  info: {
    type: Object,
    default: {}
  }
})
const tableRef = ref(null)
const dataSource = ref([])
const data = ref([])
const total = ref(0)
const loading = ref(false)
const pageSize = ref(50)
const navList = ref([
  { title: '保险成本', value: 0, key: 'insuranceCost' },
  { title: '累计发车价', value: 0, key: 'transportCost' },
  { title: '合同总金额', value: 0, key: 'contractAmt' },
  { title: '税费', value: 0, key: 'invoiceCost' },
  { title: '返款金额', value: 0, key: 'refundFee' },
  { title: '其他费用', value: 0, key: 'additionalFeeTotal' },
  { title: '毛利', value: 0, key: 'grossProfit' },
])


const searchAdd = (e) => {
  if (e.customerName) {
    dataSource.value = data.value.filter(item => {
      return item.customerName.includes(e.customerName)
    })

  } else {
    dataSource.value = data.value
  }
  if (e.sourceMan) {
    dataSource.value = dataSource.value.filter(item => {
      return item.sourceMan.includes(e.sourceMan)
    })

  }
  if (e.vinNo) {
    dataSource.value = dataSource.value.filter(item => {
      return item.vinNo.includes(e.vinNo)
    })

  }
  total.value = dataSource.value.length
  navList.value.forEach(opt => {
    if (dataSource.value.length > 0) {
      opt.value = compute('+', ...dataSource.value.map(item => item[opt.key])).result
    } else {
      opt.value = 0
    }
  })
}
const resetBtn = () => {
  pageSize.value = 50
  dataSource.value = data.value.slice(0, pageSize.value)
  total.value = data.value.length
  navList.value.forEach(opt => {
    if(data.value.length > 0){
        opt.value = compute('+', ...data.value.map(item => item[opt.key])).result
      }else{
        opt.value = 0
      }
  })
}
const getOrderRefundDetails = () => {
  loading.value = true
  financeCenterOrgDetail({
    year: props.info.year,
    month: props.info.month,
    orgId: props.info.orgId
  }).then(res => {
    if (res.code !== 10000 || !res.data) return
    data.value = res.data
    total.value = res.data.length
    navList.value.forEach(opt => {
      if(data.value.length > 0){
        opt.value = compute('+', ...data.value.map(item => item[opt.key])).result
      }else{
        opt.value = 0
      }
    })
    dataSource.value = res.data.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
onMounted(() => {
  getOrderRefundDetails()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
})

const iptData = ref([
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "分单员",
    value: null,
    prop: "sourceMan",
    width: 200,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '分单员',
    dataIndex: 'sourceMan',
    align: 'left',
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'left',
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'left',
  },
  {
    title: '推送时间',
    dataIndex: 'pushTime',
    align: 'left',
  },
  {
    title: '保险成本',
    dataIndex: 'insuranceCost',
    align: 'right',
  },
  {
    title: '累计发车价',
    dataIndex: 'transportCost',
    align: 'right',
  },
  {
    title: '合同总金额',
    dataIndex: 'contractAmt',
    align: 'right',
  },
  {
    title: '税费',
    dataIndex: 'invoiceCost',
    align: 'right',
  },
  {
    title: '返款金额',
    dataIndex: 'refundFee',
    align: 'right',
  },
  {
    title: '其他费用',
    dataIndex: 'additionalFeeTotal',
    align: 'right',
  },
  {
    title: '毛利',
    dataIndex: 'grossProfit',
    align: 'right',
  },
  {
    title: '亏损原因',
    dataIndex: 'add',
    align: 'left',
    slots: {
      customRender: 'add'
    },
    width: 200
  },
])

</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>