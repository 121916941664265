<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle"> {{title}}&nbsp;&nbsp;&nbsp;共 <a>{{data.length}}</a> 辆 &nbsp;&nbsp;&nbsp;开票总金额：<a>￥{{amtTotal}}</a>元</div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #vinNo="{ record }">
          <div>{{ record.vinNo }}</div>
          {{ record.brand }}&nbsp;{{ record.model }}
        </template>

      <template #startAddress="{ record }" >
        <div>起：{{ record. startAddress}}</div>
        <div>终：{{ record. endAddress}}</div>
      </template>
      <template #orderSettlement="{ record }" >
       <div class="flex ">
        {{ record.orderSettlement.label }}
        <div class="m-l1">{{ record.settlementStatus.label}}</div>
       </div>
      </template>
      </a-table>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import { invoiceStatisticVehicle } from '@/api/financialCenter/financialCenter'
import { getTableBody, IfNull, compute } from '@/utils/util'
import { message } from 'ant-design-vue'
const props = defineProps({
  empId: {
    type: String,
    default: ''
  },
  info: {
    type: Object,
    default: {}
  }
})
const tableRef = ref(null)
const dataSource = ref([])
const data = ref([])
const loading = ref(false)
const pageSize = ref(50)
const searchMsg = ref({})
const title = ref('')
const amtTotal = ref(0)


const searchAdd = (e) => {
  searchMsg.value = e
  getOrderRefundDetails()
}
const resetBtn = () => {
  searchMsg.value = {}
  getOrderRefundDetails()
}
const getOrderRefundDetails = () => {
  loading.value = true
  invoiceStatisticVehicle({
    ...searchMsg.value,
    taxRateSubjectId:props.info.taxRateSubjectId,
    orgId: props.info.orgId,
    applyInvoice: props.info.applyInvoice
  }).then(res => {
    if (res.code !== 10000 || !res.data) return
    data.value = res.data
    dataSource.value = res.data.slice(0, pageSize.value)
    amtTotal.value =  compute('+', ...data.value.map(item => item.contractAmt)).result
  }).finally(() => {
    loading.value = false
  })
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
onMounted(() => {
  title.value = props.info.invoiceCarrier + ' ' + props.info.taxRateSubjectName + ' - ' + props.info.taxItem 
  getOrderRefundDetails()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
})

const iptData = ref([
  {
    type: 'select',
    placeholder: '结算状态',
    value: null,
    prop: 'settlementStatus',
    width: 200,
    opt: [
      { name: '未结算', id: 0 },
      { name: '部分结算', id: 1 },
      { name: '已结算', id: 2 }
    ]
  },
  {
    type: 'select',
    placeholder: '开票类型',
    value: null,
    prop: 'applyInvoiceType',
    width: 200,
    opt: [
      { name: '开票', id: 1 },
      { name: '红冲', id: 3 },
    ]
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "小车车牌",
    value: null,
    prop: "vinNo",
    width: 200,
  },
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "录单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'left',
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'left',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots: {
      customRender: 'startAddress'
    },
    align: 'left',
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'left',
  },
  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'left',
  },
  {
    title: '分单员',
    dataIndex: 'sourceMan',
    align: 'left',
  },
  {
    title: '完成时间',
    dataIndex: 'completeTime',
    align: 'left',
  },
  {
    title: '开票类型',
    dataIndex: 'applyInvoiceType.label',
    align: 'left',
  },
  {
    title: '开票金额',
    dataIndex: 'contractAmt',
    align: 'right',
  },
  {
    title: '结算信息',
    dataIndex: 'orderSettlement',
    align: 'left',
    slots: {
      customRender: 'orderSettlement'
    },
  },
])

</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>